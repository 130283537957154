import { Theme, ThemeContext } from "assets";
import { graphql, useStaticQuery } from "gatsby";
import { convertHero } from "molecules";
import { convertComponentList, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

import { ExhibitionsCard } from "../../components/atoms";

const InternalExhibitions = (props) => {
  const exhibitionsData = props?.craftAPI?.entry;
  const exhibitionsList = props?.craftAPI?.entries?.filter((entry) => {
    return Object.values(entry).some(
      (value) => value !== undefined && value !== null
    );
  });

  exhibitionsData.heroType = "story";
  exhibitionsData.title = "Exhibitions";
  exhibitionsData.heroSubtype = "exhibitionsPage";

  const children = convertComponentList(exhibitionsData?.componentList);
  children.unshift(convertHero(exhibitionsData));

  return (
    <ThemeContext.Provider
      value={{
        theme: "",
        fontType: "",
      }}
    >
      <PageLayout theme={Theme.Black} title={"Exhibitions"}>
        {children}
        <div className={"container-fluid"}>
          {exhibitionsList?.map((exhibit, i) => {
            if (exhibit.uri === "making-a-way-out-of-no-way") {
              exhibit.uri = "making-a-way-entrance";
            }
            if (exhibit.uri === "slavery-and-freedom") {
              exhibit.uri = "elevator";
            }
            return (
              <ExhibitionsCard
                btnText={exhibit?.btnText}
                coverImage={exhibit?.coverImage?.[0]}
                endDate={exhibit?.endDate}
                exhibitCardBackground={exhibit?.exhibitCardBackground}
                exhibitCardTextColor={exhibit?.exhibitCardTextColor}
                exhibitChildren={exhibit?.children}
                fontType={exhibit?.fontType}
                formattedTitleOverride={exhibit?.formattedTitleOverride}
                key={i}
                location={exhibit?.location}
                longDescription={exhibit?.longDescription}
                pageLink={exhibit?.uri}
                presentationType={exhibit?.presentationType}
                shortDescription={exhibit?.shortDescription}
                startDate={exhibit?.startDate}
                title={exhibit.title}
                twoColumns={exhibit?.twoColumns}
              />
            );
          })}
        </div>
      </PageLayout>
    </ThemeContext.Provider>
  );
};

InternalExhibitions.propTypes = {
  craftAPI: PropTypes.shape({
    entries: PropTypes.arrayOf(PropTypes.shape({})),
    entry: PropTypes.shape({}),
  }),
};

const Exhibitions = (props) => {
  const exhibitionsPageQuery = useStaticQuery(graphql`
    query {
      craftAPI {
        entry(type: "exhibitions") {
          ... on CraftAPI_exhibitions_exhibitions_Entry {
            id
            coverImage {
              ...ImageMetadataFragment
            }
            coverImageCropStyle
            coverImagePositionOverride
            shortDescription
          }
        }
        entries {
          ... on CraftAPI_exhibit_exhibit_Entry {
            id
            presentationType
            endDate
            startDate
            uri
            shortDescription
            location
            longDescription
            title
            formattedTitleOverride
            fontType
            btnText
            exhibitCardTextColor
            exhibitCardBackground
            twoColumns
            coverImage {
              ...ImageMetadataFragment
            }
            children {
              ... on CraftAPI_exhibit_pillar_Entry {
                id
                title
                uri
              }
            }
          }
        }
      }
    }
  `);

  return <InternalExhibitions {...exhibitionsPageQuery} {...props} />;
};

export default Exhibitions;
